import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Favorite, FavoriteOID } from '../../models/favorite';
import { FavoriteSitesActions } from '../actions/favorite-site';

export type FavoriteSitesState = Record<FavoriteOID, Favorite>;

export const FAVORITE_SITES_INITIAL_STATE: FavoriteSitesState = {};

export default createReducer(
    FAVORITE_SITES_INITIAL_STATE,

    on(FavoriteSitesActions.favoritesLoaded, (state, { favorites }): FavoriteSitesState => {
        const copyState = {};

        favorites.forEach((favorite) => {
            copyState[favorite.favoriteOID] = favorite;
        });

        return copyState;
    }),

    on(FavoriteSitesActions.favoriteAdded, (state, { favorite }): FavoriteSitesState => {
        const copyState = { ...state };
        copyState[favorite.favoriteOID] = favorite;

        return copyState;
    }),

    on(FavoriteSitesActions.favoriteRemoved, (state, { favoriteOID }): FavoriteSitesState => {
        if (!state[favoriteOID]) {
            return state;
        }

        const copyState = { ...state };

        delete copyState[favoriteOID];

        return copyState;
    }),
);

export const getFavoriteSitesState = createFeatureSelector<FavoriteSitesState>('site-favorite');
